import { NodeViewContent, NodeViewProps, NodeViewWrapper } from "@tiptap/react";

export const CodeBlockComponent = (props: NodeViewProps) => {
  const language = props.node.attrs.language || null;

  return (
    <NodeViewWrapper className="relative">
      <select
        contentEditable={false}
        defaultValue={language}
        onChange={(event) => props.updateAttributes({ language: event.target.value })}
        className="absolute right-2 top-2 bg-gray-50 pr-8 appearance-none border rounded px-2 py-1 text-xs"
        style={{
          backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path fill="Black" d="M7 10l5 5 5-5z"/></svg>')`,
          backgroundRepeat: "no-repeat",
          backgroundPosition: "right 4px center",
        }}
      >
        <option value="null">auto</option>
        <option disabled>—</option>
        {props.extension.options.lowlight.listLanguages().map((lang: string, index: number) => (
          <option key={index} value={lang}>
            {lang}
          </option>
        ))}
      </select>
      <pre className="bg-gray-900 rounded-lg text-white font-mono text-sm my-6 p-3">
        <NodeViewContent as="code" />
      </pre>
    </NodeViewWrapper>
  );
};
