import { useQuery } from "@apollo/client";
import { compact, find, some } from "lodash";
import { gql } from "../__generatedGQL__/gql";
import { GetResourcesQuery, ResourceType } from "../__generatedGQL__/graphql";
import { GET_REPOS } from "../containers/GithubSettings";

export const GET_RESOURCES = gql(`
  query GetResources {
    resources {
      id
      externalId
      type
      userId
      createdAt
      updatedAt
      config
    }
  }
`);

type UseExternalTicketingHookResult = {
  loading: boolean;
  error: any;
  isExternalTicketingConnected: boolean;
  githubResource?: GetResourcesQuery["resources"][number];
  connectedTicketingResources: Array<GetResourcesQuery["resources"][number]>;
  hasEnabledRepos: boolean;
  hasTestingEnabledRepos: boolean;
};

export const useExternalTicketingHook = (): UseExternalTicketingHookResult => {
  const {
    loading: resourcesLoading,
    error: resourcesError,
    data: resourcesData,
  } = useQuery(GET_RESOURCES);
  const githubResource = find(resourcesData?.resources, { type: ResourceType.Github });

  const {
    loading: reposLoading,
    error: reposError,
    data: reposData,
  } = useQuery(GET_REPOS, {
    variables: { resourceId: githubResource?.id },
    skip: !githubResource?.id,
  });

  if (resourcesLoading || reposLoading || resourcesError || reposError) {
    return {
      loading: resourcesLoading || reposLoading,
      error: resourcesError || reposError,
      isExternalTicketingConnected: false,
      githubResource: undefined,
      connectedTicketingResources: [],
      hasEnabledRepos: false,
      hasTestingEnabledRepos: false,
    };
  }

  const linearResource = find(resourcesData?.resources, { type: ResourceType.Linear });
  const jiraResource = find(resourcesData?.resources, { type: ResourceType.Jira });
  const notionResource = find(resourcesData?.resources, { type: ResourceType.Notion });

  const isGithubConnected = !!githubResource;
  const isExternalTicketingConnected =
    !!linearResource ||
    !!jiraResource ||
    !!notionResource ||
    (isGithubConnected && githubResource.config?.issuesIntegrationEnabled);

  const hasEnabledRepos = some(reposData?.repos, "enabled");
  const hasTestingEnabledRepos = some(
    reposData?.repos,
    (repo) => repo.testingConfig?.testCheckEnabled,
  );

  return {
    loading: false,
    error: null,
    isExternalTicketingConnected,
    githubResource,
    connectedTicketingResources: compact([
      linearResource,
      jiraResource,
      notionResource,
      githubResource,
    ]),
    hasEnabledRepos,
    hasTestingEnabledRepos,
  };
};
