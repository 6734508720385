import Editor, { EditorProps } from "@monaco-editor/react";
import clsx from "clsx";
import { editor } from "monaco-editor";

export const CodeEditor = ({
  initialCode,
  onCodeChange,
  language,
  readOnly = false,
  className,
  editorOptions,
}: {
  initialCode: string;
  onCodeChange?: (value: string) => void;
  language: MonacoCodeEditorLanguage;
  className?: string;
  readOnly?: boolean;
  editorOptions?: editor.IEditorOptions;
}) => {
  return (
    <div className={clsx("h-full w-full", className)}>
      <Editor
        value={initialCode}
        language={language}
        onChange={onCodeChange}
        options={{
          minimap: { enabled: false },
          readOnly,
          scrollBeyondLastLine: false,
          ...editorOptions,
        }}
      />
    </div>
  );
};

// Got this by calling languages.getLanguages()
const MONACO_LANGUAGES = {
  plaintext: { extensions: [".txt"] },
  abap: { extensions: [".abap"] },
  apex: { extensions: [".cls"] },
  azcli: { extensions: [".azcli"] },
  bat: { extensions: [".bat", ".cmd"] },
  bicep: { extensions: [".bicep"] },
  cameligo: { extensions: [".mligo"] },
  clojure: { extensions: [".clj", ".cljs", ".cljc", ".edn"] },
  coffeescript: { extensions: [".coffee"] },
  c: { extensions: [".c", ".h"] },
  cpp: { extensions: [".cpp", ".cc", ".cxx", ".hpp", ".hh", ".hxx"] },
  csharp: { extensions: [".cs", ".csx", ".cake"] },
  csp: { extensions: [".csp"] },
  css: { extensions: [".css"] },
  cypher: { extensions: [".cypher", ".cyp"] },
  dart: { extensions: [".dart"] },
  dockerfile: { extensions: [".dockerfile"] },
  ecl: { extensions: [".ecl"] },
  elixir: { extensions: [".ex", ".exs"] },
  flow9: { extensions: [".flow"] },
  fsharp: { extensions: [".fs", ".fsi", ".ml", ".mli", ".fsx", ".fsscript"] },
  freemarker2: { extensions: [".ftl", ".ftlh", ".ftlx"] },
  "freemarker2.tag-angle.interpolation-dollar": { extensions: [] },
  "freemarker2.tag-bracket.interpolation-dollar": { extensions: [] },
  "freemarker2.tag-angle.interpolation-bracket": { extensions: [] },
  "freemarker2.tag-bracket.interpolation-bracket": { extensions: [] },
  "freemarker2.tag-auto.interpolation-dollar": { extensions: [] },
  "freemarker2.tag-auto.interpolation-bracket": { extensions: [] },
  go: { extensions: [".go"] },
  graphql: { extensions: [".graphql", ".gql"] },
  handlebars: { extensions: [".handlebars", ".hbs"] },
  hcl: { extensions: [".tf", ".tfvars", ".hcl"] },
  html: {
    extensions: [".html", ".htm", ".shtml", ".xhtml", ".mdoc", ".jsp", ".asp", ".aspx", ".jshtm"],
  },
  ini: { extensions: [".ini", ".properties", ".gitconfig"] },
  java: { extensions: [".java", ".jav"] },
  javascript: { extensions: [".js", ".es6", ".jsx", ".mjs", ".cjs"] },
  julia: { extensions: [".jl"] },
  kotlin: { extensions: [".kt", ".kts"] },
  less: { extensions: [".less"] },
  lexon: { extensions: [".lex"] },
  lua: { extensions: [".lua"] },
  liquid: { extensions: [".liquid", ".html.liquid"] },
  m3: { extensions: [".m3", ".i3", ".mg", ".ig"] },
  markdown: {
    extensions: [".md", ".markdown", ".mdown", ".mkdn", ".mkd", ".mdwn", ".mdtxt", ".mdtext"],
  },
  mdx: { extensions: [".mdx"] },
  mips: { extensions: [".s"] },
  msdax: { extensions: [".dax", ".msdax"] },
  mysql: { extensions: [] },
  "objective-c": { extensions: [".m"] },
  pascal: { extensions: [".pas", ".p", ".pp"] },
  pascaligo: { extensions: [".ligo"] },
  perl: { extensions: [".pl", ".pm"] },
  pgsql: { extensions: [] },
  php: { extensions: [".php", ".php4", ".php5", ".phtml", ".ctp"] },
  pla: { extensions: [".pla"] },
  postiats: { extensions: [".dats", ".sats", ".hats"] },
  powerquery: { extensions: [".pq", ".pqm"] },
  powershell: { extensions: [".ps1", ".psm1", ".psd1"] },
  proto: { extensions: [".proto"] },
  pug: { extensions: [".jade", ".pug"] },
  python: { extensions: [".py", ".rpy", ".pyw", ".cpy", ".gyp", ".gypi"] },
  qsharp: { extensions: [".qs"] },
  r: { extensions: [".r", ".rhistory", ".rmd", ".rprofile", ".rt"] },
  razor: { extensions: [".cshtml"] },
  redis: { extensions: [".redis"] },
  redshift: { extensions: [] },
  restructuredtext: { extensions: [".rst"] },
  ruby: { extensions: [".rb", ".rbx", ".rjs", ".gemspec", ".pp"] },
  rust: { extensions: [".rs", ".rlib"] },
  sb: { extensions: [".sb"] },
  scala: { extensions: [".scala", ".sc", ".sbt"] },
  scheme: { extensions: [".scm", ".ss", ".sch", ".rkt"] },
  scss: { extensions: [".scss"] },
  shell: { extensions: [".sh", ".bash"] },
  sol: { extensions: [".sol"] },
  aes: { extensions: [".aes"] },
  sparql: { extensions: [".rq"] },
  sql: { extensions: [".sql"] },
  st: {
    extensions: [".st", ".iecst", ".iecplc", ".lc3lib", ".TcPOU", ".TcDUT", ".TcGVL", ".TcIO"],
  },
  swift: { extensions: [".swift"] },
  systemverilog: { extensions: [".sv", ".svh"] },
  verilog: { extensions: [".v", ".vh"] },
  tcl: { extensions: [".tcl"] },
  twig: { extensions: [".twig"] },
  typescript: { extensions: [".ts", ".tsx", ".cts", ".mts"] },
  typespec: { extensions: [".tsp"] },
  vb: { extensions: [".vb"] },
  wgsl: { extensions: [".wgsl"] },
  xml: {
    extensions: [
      ".xml",
      ".xsd",
      ".dtd",
      ".ascx",
      ".csproj",
      ".config",
      ".props",
      ".targets",
      ".wxi",
      ".wxl",
      ".wxs",
      ".xaml",
      ".svg",
      ".svgz",
      ".opf",
      ".xslt",
      ".xsl",
    ],
  },
  yaml: { extensions: [".yaml", ".yml"] },
  json: {
    extensions: [".json", ".bowerrc", ".jshintrc", ".jscsrc", ".eslintrc", ".babelrc", ".har"],
  },
};
export type MonacoCodeEditorLanguage = keyof typeof MONACO_LANGUAGES;
