import { jsx as m } from "react/jsx-runtime";
import { useState as o, useEffect as e } from "react";
function u(i) {
  var n, f;
  const [d, l] = o(!1);
  return e(() => {
    const t = document.createElement("script");
    return t.id = "mintlify-widget", document.getElementById("mintlify-widget") || (t.src = "https://unpkg.com/@mintlify/widget@^0", t.onload = () => l(!0), document.head.appendChild(t)), () => {
      document.getElementById("mintlify-widget") && document.head.removeChild(t);
    };
  }, []), e(() => {
    window.updateMintlifyWidget && window.updateMintlifyWidget(i);
  }, [i]), e(() => {
    d && window.MintlifyWidget.init(i);
  }, [d]), ((f = (n = i.display) == null ? void 0 : n.trigger) == null ? void 0 : f.type) === "input" ? /* @__PURE__ */ m("div", { id: "mintlify-widget-wrapper" }) : null;
}
export {
  u as MintlifyWidget
};
