export const getTestingSandboxConfigName = ({
  appDir,
  testFramework,
  description,
}: {
  appDir?: string;
  testFramework?: string;
  description?: string;
}): string => {
  if (description) {
    return description;
  }

  let name = "";
  if (appDir) {
    name += appDir;
  }
  if (appDir && testFramework) {
    name += " - ";
  }
  if (testFramework) {
    name += testFramework;
  }
  return name;
};
