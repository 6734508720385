import clsx from "clsx";
import { useEffect, useId, useState } from "react";
import { all, createLowlight } from "lowlight";
import Highlight from "@tiptap/extension-highlight";
import Typography from "@tiptap/extension-typography";
import { Editor, EditorContent, ReactNodeViewRenderer, useEditor } from "@tiptap/react";
import StarterKit from "@tiptap/starter-kit";
import CodeBlockLowlight from "@tiptap/extension-code-block-lowlight";
import { ArrowsPointingOutIcon } from "@heroicons/react/24/outline";
import Placeholder from "@tiptap/extension-placeholder";

import js from "highlight.js/lib/languages/javascript";
import ts from "highlight.js/lib/languages/typescript";
import html from "highlight.js/lib/languages/xml";
import python from "highlight.js/lib/languages/python";
import php from "highlight.js/lib/languages/php";
import bash from "highlight.js/lib/languages/bash";
import json from "highlight.js/lib/languages/json";
import markdown from "highlight.js/lib/languages/markdown";
import go from "highlight.js/lib/languages/go";
import java from "highlight.js/lib/languages/java";

import { Button } from "../../Button";
import { CodeBlockComponent } from "./CodeBlockComponent";
import { Dialog } from "@headlessui/react";

// we could also just do createLowlight(all) but to avoid a huge list of languages we register them individually
const lowlight = createLowlight();

lowlight.register("javascript", js);
lowlight.register("typescript", ts);
lowlight.register("html", html);
lowlight.register("python", python);
lowlight.register("php", php);
lowlight.register("bash", bash);
lowlight.register("json", json);
lowlight.register("markdown", markdown);
lowlight.register("go", go);
lowlight.register("java", java);

interface MarkdownEditorProps {
  editorClassName?: string;
  placeholder?: string;
  initialContent?: string;
  onUpdate: (html: string) => void;
  onSave?: () => Promise<void>;
}

export const MarkdownEditor = ({
  editorClassName,
  placeholder,
  initialContent,
  onUpdate,
  onSave,
}: MarkdownEditorProps) => {
  // Set initial content to empty paragraph tags if not provided (since that's the default initial content in editor)
  // This handles case where initialContent is undefined or empty string
  initialContent = initialContent || "<p></p>";

  const [isSaving, setIsSaving] = useState(false);
  const [isFocused, setIsFocused] = useState(false);
  const [isExpanded, setIsExpanded] = useState(false);
  const [lastSavedContent, setLastSavedContent] = useState(initialContent);

  const commonEditorClassNames = clsx(
    "focus:outline-none",
    // This allows the editor to be styled via Tailwind prose classes
    // In general we reduce margin of elements to make editor more compact
    // See default styling here - https://github.com/tailwindlabs/tailwindcss-typography/blob/main/src/styles.js
    "prose prose-sm max-w-none prose-p:my-1 prose-li:-my-1 prose-ol:-my-1 prose-ul:mt-2 prose-pre:my-3 prose-headings:my-2",
    // Add this class to remove top margin from first child
    "[&>*:first-child]:!mt-0",
    // Syntax highlighting for inline code (`code`)
    "[&_code]:font-normal [&_code]:bg-purple-50 [&_code]:before:content-[''] [&_code]:after:content-[''] [&_code]:px-1 [&_code]:py-0.5 [&_code]:rounded [&_pre_code]:p-0",
    // Syntax highlighting and formatting in code blocks
    "[&_pre_code]:leading-normal [&_pre_.hljs-comment]:text-gray-400 [&_pre_.hljs-quote]:text-gray-400 [&_pre_.hljs-variable]:text-red-300 [&_pre_.hljs-template-variable]:text-red-300 [&_pre_.hljs-tag]:text-red-300 [&_pre_.hljs-name]:text-red-300 [&_pre_.hljs-selector-id]:text-red-300 [&_pre_.hljs-selector-class]:text-red-300 [&_pre_.hljs-regexp]:text-red-300 [&_pre_.hljs-number]:text-orange-300 [&_pre_.hljs-built_in]:text-orange-300 [&_pre_.hljs-literal]:text-orange-300 [&_pre_.hljs-type]:text-orange-300 [&_pre_.hljs-params]:text-orange-300 [&_pre_.hljs-string]:text-green-300 [&_pre_.hljs-symbol]:text-green-300 [&_pre_.hljs-bullet]:text-green-300 [&_pre_.hljs-title]:text-yellow-200 [&_pre_.hljs-section]:text-yellow-200 [&_pre_.hljs-keyword]:text-blue-300 [&_pre_.hljs-selector-tag]:text-blue-300",
  );

  const commonExtensions = [
    StarterKit.configure({
      codeBlock: false,
    }),
    placeholder
      ? Placeholder.configure({
          placeholder,
          emptyEditorClass:
            "before:content-[attr(data-placeholder)] before:float-left before:h-0 before:pointer-events-none prose prose-sm before:text-gray-400",
        })
      : undefined,
    Highlight,
    Typography,
    CodeBlockLowlight.extend({
      addNodeView() {
        return ReactNodeViewRenderer(CodeBlockComponent);
      },
    }).configure({ lowlight }),
  ];

  const editor = useEditor({
    onUpdate({ editor }) {
      const html = editor.getHTML();
      onUpdate(html);
    },
    onFocus() {
      setIsFocused(true);
    },
    editorProps: {
      attributes: {
        class: clsx("overflow-y-auto", commonEditorClassNames, editorClassName),
      },
    },
    extensions: commonExtensions,
    content: initialContent,
  });

  const expandedEditor = useEditor({
    content: editor?.getHTML() || initialContent,
    onUpdate({ editor: expandedEd }) {
      const html = expandedEd.getHTML();
      onUpdate(html);
    },
    onFocus() {
      setIsFocused(true);
    },
    editorProps: {
      attributes: {
        class: clsx("h-full", commonEditorClassNames, editorClassName),
      },
    },
    extensions: commonExtensions,
  });

  const handleExpand = () => {
    if (expandedEditor && editor) {
      const html = editor.getHTML();
      expandedEditor.commands.setContent(html);
    }
    setIsExpanded(true);
  };

  const handleCloseExpanded = () => {
    if (expandedEditor && editor) {
      const html = expandedEditor.getHTML();
      editor.commands.setContent(html);
    }
    setIsExpanded(false);
  };

  const handleSave = onSave
    ? async () => {
        setIsSaving(true);
        editor?.setEditable(false);
        await onSave();
        setLastSavedContent(editor?.getHTML() || "");
        editor?.setEditable(true);
        setIsSaving(false);
      }
    : undefined;

  const handleExpandedSave = onSave
    ? async () => {
        setIsSaving(true);
        expandedEditor?.setEditable(false);
        await onSave();
        expandedEditor?.setEditable(true);
        setLastSavedContent(expandedEditor?.getHTML() || "");
        setIsSaving(false);
        handleCloseExpanded();
      }
    : undefined;

  return (
    <>
      <div
        className={clsx(
          "py-4 pl-4 pr-2 border border-gray-200 rounded-lg focus-within:border-purple-600 focus-within:ring-purple-600 focus-within:ring-1 focus-within:outline-none relative",
        )}
      >
        {isFocused && (
          <div className="absolute top-2 right-2">
            <button
              className="p-1 text-gray-400 hover:text-gray-600 rounded-lg shadow-sm bg-white/80 border border-gray-100/50 -mt-2 -mr-1 relative z-10"
              onClick={() => {
                handleExpand();
              }}
            >
              <ArrowsPointingOutIcon className="w-4 h-4" />
            </button>
          </div>
        )}

        <EditorContent editor={editor} />
        {onSave && (
          <div className="absolute bottom-2 right-2 flex justify-end gap-2 pr-2 pb-2">
            <Button
              disabled={isSaving || !onSave || lastSavedContent === editor?.getHTML() || isExpanded}
              loading={isSaving && !isExpanded}
              size="sm"
              variant="secondary"
              onClick={handleSave}
            >
              Save
            </Button>
          </div>
        )}
      </div>

      <Dialog open={isExpanded} onClose={handleCloseExpanded} className="relative z-50">
        <div className="fixed inset-0 bg-black/30" aria-hidden="true" />

        <div className="fixed inset-0 flex items-center justify-center p-4">
          <Dialog.Panel className="w-full max-w-4xl bg-white rounded-lg shadow-xl">
            <div className="relative h-[80vh]">
              <button
                className="absolute top-2 right-2 p-1 text-gray-400 hover:text-gray-600 rounded-lg z-10"
                onClick={handleCloseExpanded}
              >
                <ArrowsPointingOutIcon className="w-4 h-4 rotate-180" />
              </button>

              <div
                className={clsx(
                  "h-full overflow-y-auto",
                  "rounded-lg",
                  "focus-within:border-purple-600 focus-within:ring-purple-600 focus-within:ring-1 focus-within:outline-none",
                  "border border-gray-200",
                )}
              >
                <EditorContent editor={expandedEditor} className="h-full p-6" />
                {onSave && (
                  <div className="absolute bottom-2 right-2 flex justify-end gap-2 pr-2 pb-2">
                    <Button
                      disabled={
                        isSaving ||
                        !onSave ||
                        lastSavedContent === expandedEditor?.getHTML() ||
                        !isExpanded
                      }
                      loading={isSaving && isExpanded}
                      size="sm"
                      variant="secondary"
                      onClick={handleExpandedSave}
                    >
                      Save
                    </Button>
                  </div>
                )}
              </div>
            </div>
          </Dialog.Panel>
        </div>
      </Dialog>
    </>
  );
};
