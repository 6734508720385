import { Navigate, Route, Routes } from "react-router-dom";
import { MintlifyWidget } from "@mintlify/widget-react";

import { AppSidebar, ErrorPage } from "../components";
import { AppProvider, NotificationProvider, useAppContext } from "../providers";

import { SettingsPage } from "./SettingsPage";
import { DashboardPage } from "./DashboardPage";
import { AdminPage } from "./AdminPage";
import { AutoTriagePage } from "./AutoTriagePage";

export const AppPage = () => {
  const { showOldTuskUI } = useAppContext();

  return (
    <div>
      <Routes>
        {/** Refactor so admin has it's own login */}
        <Route path="/admin/*" element={<AdminPage />} />
        <Route
          path="/*"
          element={
            <AppSidebar>
              <NotificationProvider>
                <Routes>
                  <Route path="/suggested-issues" element={<AutoTriagePage />} />
                  <Route path="/settings/*" element={<SettingsPage />} />
                  <Route path="/task/:taskId" element={<DashboardPage />} />
                  {showOldTuskUI ? (
                    <Route path="/" element={<DashboardPage />} />
                  ) : (
                    <Route path="/" element={<Navigate to="/app/settings" replace />} />
                  )}
                  <Route path="*" element={<ErrorPage />} />
                </Routes>
                {process.env.MINTLIFY_CHAT_API_KEY && (
                  <MintlifyWidget
                    connection={{
                      apiKey: process.env.MINTLIFY_CHAT_API_KEY,
                    }}
                    display={{
                      colors: {
                        primary: "#7D36E1",
                      },
                    }}
                  />
                )}
              </NotificationProvider>
            </AppSidebar>
          }
        />
      </Routes>
    </div>
  );
};
