import { clsx } from "clsx";
import { Button } from "../../../components/Button";

interface VerticalNavigationProps {
  currentTestingSandboxConfigId: string;
  currentTab: "instructions" | "execution";
  testingSandboxConfigOptions: {
    id: string;
    name: string;
  }[];
  onSelectTestingSandboxConfig: (id: string, tab: "instructions" | "execution") => void;
}

export const VerticalNavigation = ({
  currentTestingSandboxConfigId,
  currentTab,
  testingSandboxConfigOptions,
  onSelectTestingSandboxConfig,
}: VerticalNavigationProps) => {
  return (
    <nav aria-label="Sidebar" className="flex flex-1 flex-col">
      <ul role="list" className="-mx-2 space-y-1">
        {testingSandboxConfigOptions.map((config) => (
          <li key={config.id}>
            <div
              className={clsx(
                "text-sm font-medium px-3 py-2",
                currentTestingSandboxConfigId === config.id ? "text-purple-600" : "text-gray-500",
              )}
            >
              {config.name}
            </div>
            <ul className="space-y-1 pl-4">
              <li>
                <a
                  onClick={() => onSelectTestingSandboxConfig(config.id, "instructions")}
                  className={clsx(
                    currentTestingSandboxConfigId === config.id && currentTab === "instructions"
                      ? "bg-gray-50 text-gray-600"
                      : "text-gray-600 hover:bg-gray-50 hover:text-gray-700",
                    "group flex gap-x-3 rounded-md p-2 pl-3 text-sm/6 font-semibold cursor-pointer",
                  )}
                >
                  Custom Instructions
                </a>
              </li>
              {false && (
                // TODO-CUSTOMIZATION: add test execution tab
                <li>
                  <a
                    onClick={() => onSelectTestingSandboxConfig(config.id, "execution")}
                    className={clsx(
                      currentTestingSandboxConfigId === config.id && currentTab === "execution"
                        ? "bg-gray-50 text-purple-600"
                        : "text-gray-700 hover:bg-gray-50 hover:text-purple-600",
                      "group flex gap-x-3 rounded-md p-2 pl-3 text-sm/6 font-semibold cursor-pointer",
                    )}
                  >
                    Test Execution
                  </a>
                </li>
              )}
            </ul>
          </li>
        ))}
      </ul>
      {false && (
        // TODO-CUSTOMIZATION: add new config button, maybe just have it contact founders?
        <Button variant="secondary" size="md" className="mt-4">
          Add new config
        </Button>
      )}
    </nav>
  );
};
